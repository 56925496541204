import { HiDownload } from 'react-icons/hi';
import styled from 'styled-components';
import { deviceMax, deviceMin } from '../../../styles/MediaQuery';
import { blue, greenDarker, greenLighter, greyBorder, greyText, white } from '../../../styles/Variables';
import { ContainerType } from '../interfaces';

export const Container = styled.div<ContainerType>`
    padding: ${(props) => (props.isActive ? '2em 0' : '0')};
    transition: max-height 0.3s;
    max-height: ${(props) => (props.isActive ? '3000px' : '0')};
    overflow: hidden;

    @media ${deviceMax.laptop} {
        padding: ${(props) => (props.isActive ? '2em 0.8em' : '0')};
    }
`;

export const Download = styled.div`
    border-radius: 50%;
    width: 3em;
    height: 3em;
    background: linear-gradient(${greenLighter}, ${greenDarker});
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: ${greenDarker};
    }
`;

export const DownloadIcon = styled(HiDownload)`
    fill: ${white};
    font-size: 1.9em;
`;

export const ProductInformation = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
        border-bottom: 1px solid ${greyBorder};
    }

    @media ${deviceMin.laptop} {
        padding: 1.3em 0;
    }
`;

export const ProductHeader = styled.h3`
    color: ${blue};
    font-size: 24px;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, Arial;
    text-transform: capitalize;
    margin-bottom: 0;
`;

export const ProductParagraph = styled.p`
    font-family: Arial, Helvetica;
    font-size: 0.875rem;
    color: ${greyText};
    margin-top: 0.7em;
    padding: 0;
`;

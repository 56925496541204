import { GoCheck } from 'react-icons/go';
import styled from 'styled-components';
import iconList from '../../../static/images/icon-li-list.png';
import { deviceMax, deviceMin } from '../../../styles/MediaQuery';
import { blueIcon, white } from '../../../styles/Variables';
import { ContainerType } from '../interfaces';

export const Container = styled.div<ContainerType>`
    padding-top: 1em;
    padding: ${(props) => (props.isActive ? '1em' : 0)};
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    transition: max-height 0.3s;
    max-height: ${(props) => (props.isActive ? '3000px' : '0')};
    overflow: hidden;

    @media ${deviceMax.laptop} {
        display: block;
        padding: 0;
        margin: 0 auto;
        width: calc(100% - 1.6em);
    }

    div p {
        font-family: Arial, Helvetica;
        font-size: 0.875rem;
        line-height: 20px;
        margin-bottom: 1.7em;
    }

    h3 {
        margin-bottom: 0.4em;
        text-transform: uppercase;
        font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, Arial;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.05rem;
    }
`;

export const SliderWrapper = styled.div`
    border-bottom: 2px solid ${white};

    @media ${deviceMax.laptop} {
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: none;
    }
`;

export const Description = styled.div`
    border-left: 1px solid ${white};
    border-bottom: 2px solid ${white};

    @media ${deviceMin.laptop} {
        padding: 0 0.8em;
    }

    @media ${deviceMax.laptop} {
        border-left: none;
        border-bottom: none;
    }
`;

export const AdvantagesListWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3px;
    margin-top: 10px;
`;

export const CheckIcon = styled(GoCheck)`
    fill: ${blueIcon};
    font-size: 1.6em;
    margin-right: 0.2em;
`;

export const AdvantagesList = styled.ul`
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-auto-rows: 1fr;
    margin-top: 0;
`;

export const AdvantagesItem = styled.li`
    font-family: Arial, Helvetica;
    margin: 0;
    padding: 0 0 0 24px;
    background: url(${iconList}) no-repeat 0% 15%;
    display: inline-block;
    vertical-align: top;
    width: 190px;
    text-align: left;
    font-size: 0.875rem;
    line-height: 24px;
    margin-bottom: 3px;

    @media (max-width: 512px) {
        width: auto;
    }
`;

export const ProductDescription = styled.div`
    font-family: Arial, Helvetica;
    font-size: 0.875rem;
    line-height: 20px;
    margin-bottom: 10px;

    * {
        font-family: Arial, Helvetica;
        font-size: 14px;
    }
`;

export const ProductPhoto = styled.div`
    max-width: 468px;
    height: 460px;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 0.5em;

    @media ${deviceMax.laptop} {
        margin: unset;
        width: 80%;
        min-width: 300px;
        height: 400px;
    }

    @media ${deviceMax.tablet} {
        height: 300px;
    }
`;
